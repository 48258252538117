/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

import logo from "assets/images/BudokaiWor.png"


function Budokaiwor() {
  return (
    <RotatingCard width="100%">
      <RotatingCardFront
        color="dark"
        image={logo}
        title={
          <>
            Budokai Wor
          </>
        }
        description={
          <Stack>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">person</Icon>
              </MKBox>
              <MKTypography variant="body2" color="white" pl={2}>
                Dennis Wor
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">phone</Icon>
              </MKBox>
              <MKTypography variant="body2" color="white" pl={2}>
                (+31) 06 - 50 60 31 75
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">mail</Icon>
              </MKBox>
              <MKTypography variant="body2" color="white" pl={2}>
                budokaiwor@gmail.com
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" p={2}>
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">home</Icon>
              </MKBox>
              <MKTypography variant="body2" color="white" pl={2}>
                Maaslaan 6, 3363CJ Sliedrecht
              </MKTypography>
            </MKBox>
            <Grid container>
              <Grid item>
                <a href="https://www.facebook.com/BudokaiWor" target="_blank">
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                    </MKBox>
                  </MKBox>
                  </a>
              </Grid>
              <Grid item>
                <a href="https://www.budukai-wor.nl/" target="_blank">
                  <MKBox display="flex" alignItems="center" p={2}>
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Icon fontSize="small">public</Icon>
                    </MKBox>
                  </MKBox>
                  </a>
              </Grid>
            </Grid>
            
            <Grid item>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKTypography variant="body2" color="white" pl={2}>
                  &nbsp;<br />
                  &nbsp;<br />
                </MKTypography>
              </MKBox>
            </Grid>
          </Stack>
        }
      />

    </RotatingCard>

  );
}

export default Budokaiwor;
