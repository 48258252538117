/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Banner.jpg";
import Contact from "components/Sections/Contact";
import SimpleFooter from "examples/Footers/SimpleFooter";
import OpenDag from "./sections/opendag";

function ActivitiesPage() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
            mt={1}
          >
            <MKTypography
              variant="ht"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Budocentrum Activiteiten
            </MKTypography>
            <MKTypography variant="h4" color="white" opacity={0.8} mt={1} mb={1.5}>
              De plaats voor budo sporten in de regio Alblasserwaard
            </MKTypography>
            <MKTypography variant="h6" color="white" mt={2.5}>
              Je kan ons vinden op:&nbsp;&nbsp;&nbsp;
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center" mb={2}>
              <MKTypography component="a" variant="body1" color="white" href="https://www.facebook.com/profile.php?id=100056867635219" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -6,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          component="section"
          variant="gradient"
          bgColor="white"
          position="relative"
          py={6}
          px={{ xs: 2, lg: 0 }}
          mx={-2}
        >
          <Container>
            <Grid container>
              <Grid item xs={12} md={8} sx={{ mb: 6 }}>
                <MKTypography variant="h3" color="dark">
                  Geplande Activiteiten
                </MKTypography>
                <MKTypography variant="body2" color="dark" opacity={0.8}>
                  De volgende activitijten staan in de aganda voor het BudoCentrum:
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>

        <OpenDag />

        <Contact />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter />
      </MKBox>
    </>
  );
}

export default ActivitiesPage;
